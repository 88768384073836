import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import TitleBlock from "../blocks/TitleBlock"
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk"
import MailOutlineIcon from "@material-ui/icons/MailOutline"

const ContactInfo = props => {
  return (
    <>
      <div className="contactInfo pb-0 pt-0" id="contact-info">
        <div className="contactInfo__description text-center pb-0">
          <div className="pb-0">
            <Row>
              <Col className="mr-auto ml-auto" md="12">
                <TitleBlock
                  title={props.title}
                  description={props.description}
                  headingLevel="h3"
                  extendedClasses="mb-0 color-sea font-weight-bold"
                />
              </Col>
              {props.extraInfo && (
                <Col md="12">
                  <p style={{ fontWeight: "bold", textAlign: "center" }}>
                    {props.extraInfo}
                  </p>
                </Col>
              )}
            </Row>
            <Row>
              <Col md="6" className="ml-auto">
                <div className="info info-hover">
                  <a href={"tel:" + props.phone} title={props.telTitle}>
                    <div className="icon icon-sea icon-circle d-flex align-items-center justify-content-center">
                      <i style={{ fontSize: "inherit" }}>
                        <PhoneInTalkIcon fontSize="large" />
                      </i>
                    </div>
                  </a>
                  <TitleBlock
                    title={props.phone}
                    description={props.telDescription}
                    headingLevel="h4"
                    extendedClasses="info-title font-weight-normal"
                  />
                </div>
              </Col>
              <Col md="6" className="mr-auto">
                <div className="info info-hover">
                  <a href={"mailto:" + props.eMail} title={props.mailTitle}>
                    <div className="icon icon-gold icon-circle d-flex align-items-center justify-content-center">
                      <i style={{ fontSize: "inherit" }}>
                        <MailOutlineIcon fontSize="large" />
                      </i>
                    </div>
                  </a>
                  <TitleBlock
                    title={props.eMail}
                    description={props.mailDescription}
                    headingLevel="h4"
                    extendedClasses="info-title font-weight-normal"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

ContactInfo.propTypes = {
  phone: PropTypes.string,
  eMail: PropTypes.string,
}

ContactInfo.defaultProps = {
  phone: "+48 791 452 000",
  eMail: "rezerwacje@morska5.pl",
}

export default ContactInfo
